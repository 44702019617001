.categories-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  .category-item {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 8px;
    background-color: var(--brand-color);
    padding: 3px 6px;
    border-radius: 8px;
    &:hover {
      background-color: var(--brand-color-hover);
    }
    a {
      display: block;
      text-align: center;
      text-decoration: none;
      font-size: 0.9em;
      color: var(--background-color);
    }
  }
}
