$category-item-color: #2980b9;
$pager-anchor-color: #2980b9;

.not-active {
   pointer-events: none;
   cursor: default;
   color: gray !important;
}

h2 {
  margin-bottom: 8px;
}

.title-break {
  margin-bottom: 16px;
}

:root {
  color-scheme: light dark;
  --site-title-color: $grey-color-dark;
  --post-link-color: black;
  --brand-color: #0a6daf;
  --brand-color-hover: #004572;
  --background-color: white;
}

@import
  "pager",
  "pagination-buttons",
  "categories-list",
  "post-link",
  "page-title",
  "dark-mode-variables",
  "splash"
;
