$pager-anchor-color: #2980b9;

.pager {
  width: 70%;
  margin: auto;
  text-align: center;;
  margin-top: 1em;
  margin-bottom: 1em;

  li {
    display: inline;
  }

  a {
    &,
    &:visited {
      color: var(--brand-color);
      text-decoration: none;

      &:hover {
        color: var(--brand-color-hover)
      }
    }
  }
}
