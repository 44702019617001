@media (prefers-color-scheme: dark) {
    :root {
        --site-title-color: #e4e6eb;
        --post-link-color: #e4e6eb;
        --brand-color: #ff6e51;
        --brand-color-hover: #ffafa0;
        --text-color: #e4e6eb;
        --background-color: #343434;
    }
    p {
        a {
            &,
            &:visited {
                color: var(--brand-color);
                text-decoration: none;
                &:hover {
                    color: var(--brand-color-hover)
                }
            }
        }
    }
    body {
        background-color: var(--background-color);
        color: var(--text-color);
    }

    .site-nav {
        @include media-query($on-palm) {
            border: 1px solid var(--text-color);    
        }
        background-color: var(--background-color);

        .menu-icon > svg {
            fill: var(--text-color);
        }

        .trigger .page-link {
            color: var(--text-color);
        }
    }

    a .username {
        color: var(--brand-color);

        &:hover {
            color: var(--brand-color-hover);
        }
    }
}